import { TAB_MAP } from "../pages/ActivityCenterDashboard/constant";
import {
  ACTIVITY_CENTER,
  ADD_PHOTO,
  MEMBERSHIP_PLAN,
  MESSENGER,
  ME_PFOILE,
  PREFERRED_MATCHES,
  SEARCH,
  SEARCH_RESULT,
  SETTINGS,
} from "../routing/routes.constant";

export const ENVIRONMENTS = {
  BASE_PATH: process.env.REACT_APP_BASE_PATH,
  ABOUT_DOMAIN: process.env.REACT_APP_ABOUT_DOMAIN,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  REACT_APP_PRODUCTION: JSON.parse(process.env.REACT_APP_PRODUCTION || false),
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_G_ANALYTICS_ID: process.env.REACT_APP_G_ANALYTICS_ID,
  REACT_APP_RAZOR_PAY_KEY: process.env.REACT_APP_RAZOR_PAY_KEY,
  REACT_APP_CONFIG: {
    AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
    AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
    AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    Public_read: process.env.REACT_APP_PUBLIC_READ,
  },
};

export const DEVICE_TYPE = {
  ANDROID: `ANDROID`,
  IOS: `ISO`,
  WEB: `WEB`,
  MWEB: `MWEB`,
};

export const DEFAULT_IMAGE_PATH = {
  INNER_LOGO: "/InnerLogo-60X60.png",
  HE: "/assets/images/he-default.jpg",
  SHE: "/assets/images/she-default.jpg",
  NO_PHOTO: `/assets/images/no-photo.png`,
  NO_PHOTO_HE: "/assets/images/he-no-image.jpg",
  NO_PHOTO_SHE: "/assets/images/she-no-image.jpg",
  SHE_NO_PHOTO_ADDED: "/assets/images/she-no-photo-added.jpg",
  SHE_NO_RUN_TIME_PHOTO_ADDED: "/assets/images/she-no-run-photo.jpg",
  HE_NO_RUN_TIME_PHOTO_ADDED: "/assets/images/he-no-run-photo.jpg",
  HE_NO_PHOTO_ADDED: "/assets/images/he-no-photo-added.jpg",
  NO_DATA_FOUND: "/assets/images/no-data-found.png",
  DEFAULT_USER: "/assets/images/default-user.jpg",
};

export const IM_LOOKING = [
  { name: "Bride", value: "female" },
  { name: "Groom", value: "male" },
];
export const IM_LOOKING_ENUM = (type = "") => {
  return IM_LOOKING.find((item) => item.value === type);
};

export const GENDER_VALUE = {
  MALE: "male",
  FEMALE: "female",
};

export const GENDER = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];
export const GENDER_ENUM = (type = "") => {
  return GENDER.find((item) => item.value === type);
};

export const PROFILE_FOR = [
  { name: "Self", value: "self" },
  { name: "Son", value: "son" },
  { name: "Daughter", value: "daughter" },
  { name: "Brother", value: "brother" },
  { name: "Sister", value: "sister" },
  { name: "Relative/Friend", value: "relative" },
  { name: "Client-Marriage Bureau", value: "client_marriage_bureau" },
];

export const PROFILE_FOR_ENUM = (type = "") => {
  return PROFILE_FOR.find((item) => item.value === type);
};

export const MARITAL_STATUS = [
  { name: "Unmarried", value: "unmarried" },
  { name: "Widow/Widower", value: "widowed" },
  { name: "Divorced", value: "divorced" },
  { name: "Awaiting Divorce", value: "awaiting_divorce" },
  { name: "Separated", value: "separated" },
];

export const MARITAL_STATUS_ENUM = (type = "") => {
  return MARITAL_STATUS.find((item) => item.value === type);
};

export const COMMUNITY = [
  { name: "Garhwali", value: "1" },
  { name: "Kumaoni", value: "2" },
];

export const COMMUNITY_ENUM = (type = "") => {
  return COMMUNITY.find((item) => item.value === type);
};

export const CASTE = [
  { name: "Brahmin", value: "1" },
  { name: "Rajput", value: "2" },
  { name: "Others", value: "3" },
];

export const CASTE_ENUM = (type = "") => {
  return CASTE.find((item) => item.value === type);
};

export const COMMON_OPTION = [
  { name: "No", value: "no" },
  { name: "Yes", value: "yes" },
  { name: "Occasionally", value: "occasionally" },
];

export const COMMON_OPTION_ENUM = (type = "") => {
  return COMMON_OPTION.find((item) => item.value === type);
};

export const COMMON_BOOLEAN_OPTION = [
  { name: "No", value: "no" },
  { name: "Yes", value: "yes" },
];

export const COMMON_BOOLEAN_OPTION_ENUM = (type = "") => {
  return COMMON_BOOLEAN_OPTION.find((item) => item.value === type);
};

export const CHALLENGED_OPTION = [
  { value: "none", name: "None" },
  { value: "physically_from_birth", name: "Physically - From birth" },
  { value: "physically_due_to_accident", name: "Physically - Due to accident" },
  { value: "mentally_from_birth", name: "Mentally - From birth" },
  { value: "mentally_due_to_accident", name: "Mentally - Due to accident" },
  { value: "other", name: "Other" },
];

export const CHALLENGED_OPTION_ENUM = (type = "") => {
  return CHALLENGED_OPTION.find((item) => item.value === type);
};

export const INCOME_OPTION = [
  { value: "0", minValue: "0", name: "No Income" },
  { value: "1L", minValue: "0", name: "Rs. 0 - 1 Lakh" },
  { value: "2L", minValue: "1L", name: "Rs. 1 - 2 Lakh" },
  { value: "3L", minValue: "2L", name: "Rs. 2 - 3 Lakh" },
  { value: "4L", minValue: "3L", name: "Rs. 3 - 4 Lakh" },
  { value: "5L", minValue: "4L", name: "Rs. 4 - 5 Lakh" },
  { value: "8L", minValue: "5L", name: "Rs. 5 - 7.5 Lakh" },
  { value: "10L", minValue: "7.5L", name: "Rs. 7.5 - 10 Lakh" },
  { value: "15L", minValue: "10L", name: "Rs. 10 - 15 Lakh" },
  { value: "20L", minValue: "15L", name: "Rs. 15 - 20 Lakh" },
  { value: "25L", minValue: "20L", name: "Rs. 20 - 25 Lakh" },
  { value: "35L", minValue: "25L", name: "Rs. 25 - 35 Lakh" },
  { value: "50L", minValue: "35L", name: "Rs. 35 - 50 Lakh" },
  { value: "75L", minValue: "50L", name: "Rs. 50 - 75 Lakh" },
  { value: "1C", minValue: "1C", name: "Rs. 1 crore & above" },
];

export const INCOME_OPTION_ENUM = (type = "", matchKey = "value") => {
  return INCOME_OPTION.find((item) => item[matchKey] === type);
};

export const NUMBER_OF_MEMBER_OPTION = [
  { value: 0, name: "None" },
  { value: 1, name: "1" },
  { value: 2, name: "2" },
  { value: 3, name: "3" },
  { value: 4, name: "4" },
  { value: 5, name: "5 & above" },
];

export const NUMBER_OF_MEMBER_OPTION_ENUM = (type = "") => {
  return NUMBER_OF_MEMBER_OPTION.find((item) => item.value === type);
};

export const FATHER_MOCCUPATION_OPTION = [
  { value: "retired", name: "Retired" },
  { value: "working", name: "Working" },
  { value: "not_alive", name: "Not Alive" },
];

export const FATHER_MOCCUPATION_OPTION_ENUM = (type = "") => {
  return FATHER_MOCCUPATION_OPTION.find((item) => item.value === type);
};

export const MOTHER_MOCCUPATION_OPTION = [
  { value: "retired", name: "Retired" },
  { value: "working", name: "Working" },
  { value: "homemaker", name: "Homemaker" },
  { value: "not_alive", name: "Not Alive" },
];

export const MOTHER_MOCCUPATION_OPTION_ENUM = (type = "") => {
  return MOTHER_MOCCUPATION_OPTION.find((item) => item.value === type);
};

export const RESIDENCE_OPTION = [
  { value: "owned", name: "Owned" },
  { value: "rented", name: "Rented" },
  { value: "with_parent", name: "Staying with parent" },
];

export const RESIDENCE_OPTION_ENUM = (type = "") => {
  return RESIDENCE_OPTION.find((item) => item.value === type);
};

export const MANGLIK_STATUS = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
  { value: "tell_later", name: "Will tell later" },
  { value: "anshik_manglik", name: "Anshik Manglik" },
];

export const PARTNER_MANGLIK_STATUS = [
  { value: "yes", name: "Manglik" },
  { value: "no", name: "Non Manglik" },
];

export const MANGLIK_STATUS_ENUM = (type = "") => {
  return MANGLIK_STATUS.find((item) => item.value === type);
};

export const NADI_STATUS = [
  { value: "dont_no", name: "Dont Know" },
  { value: "adi_nadi", name: "Adi Nadi" },
  { value: "antya_nadi", name: "Antya Nadi" },
  { value: "madhya_nadi", name: "Madhya Nadi" },
];

export const NADI_STATUS_ENUM = (type = "") => {
  return NADI_STATUS.find((item) => item.value === type);
};

export const HOROSCOPE_STATUS = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
];

export const HOROSCOPE_STATUS_ENUM = (type = "") => {
  return HOROSCOPE_STATUS.find((item) => item.value === type);
};

export const MOONSIGN_STATUS = [
  { value: "aries", name: "Mesh (Aries)" },
  { value: "taurus", name: "Vrishabh (Taurus)" },
  { value: "gemini", name: "Mithun (Gemini)" },
  { value: "cancer", name: "Karka (Cancer)" },
  { value: "leo", name: "Simha (Leo)" },
  { value: "virgo", name: "Kanya (Virgo)" },
  { value: "libra", name: "Tula (Libra)" },
  { value: "scorpio", name: "Vrischika (Scorpio)" },
  { value: "sagittarious", name: "Dhanu (Sagittarious)" },
  { value: "capricorn", name: "Makar (Capricorn)" },
  { value: "aquarious", name: "Kumbha (Aquarious)" },
  { value: "pisces", name: "Meen (Pisces)" },
];

export const MOONSIGN_STATUS_ENUM = (type = "") => {
  return MOONSIGN_STATUS.find((item) => item.value === type);
};

export const DITE_TYPE = [
  { name: "Vegetarian", value: "vegetarian" },
  { name: "Eggetarian", value: "eggetarian" },
  { name: "Occasionally Non-Vegetarian", value: "occasionally_non_vegetarian" },
  { name: "Non-Vegetarian", value: "non_vegetarian" },
  { name: "Jain", value: "jain" },
  { name: "Vegan", value: "vegan" },
];

export const DITE_TYPE_OPTION_ENUM = (type = "") => {
  return DITE_TYPE.find((item) => item.value === type);
};

export const EMPLOYED_IN_TYPE = [
  { name: "Business", value: "business" },
  { name: "Defence", value: "defence" },
  { name: "Government", value: "government" },
  { name: "Private", value: "private" },
  { name: "Not Employed in", value: "not_employed_in" },
  { name: "Others", value: "other" },
];

export const EMPLOYED_IN_TYPE_OPTION_ENUM = (type = "") => {
  return EMPLOYED_IN_TYPE.find((item) => item.value === type);
};

export const PROFILE_NAV = {
  isArrow: false,
  items: [
    { name: "My Profile", link: ME_PFOILE.fullUrl },
    {
      name: "My Photos",
      link: ADD_PHOTO.fullUrl,
    },
    {
      name: "Partner Preferences",
      link: PREFERRED_MATCHES.fullUrl,
    },
    {
      name: "Settings",
      link: SETTINGS.fullUrl,
      border: true,
    },
    { name: "Logout", id: "LOGOUT" },
  ],
};

export const MASTER_NAV_MENU = [
  {
    name: "Matches",
    items: [
      {
        name: "My Matches",
        link: `${SEARCH_RESULT.fullUrl}/preferred-matches`,
        clearData: true,
      },
      {
        name: "Govt Matches",
        link: `${SEARCH_RESULT.fullUrl}/govt-matches`,
        clearData: true,
      },
      {
        name: "Army Officer Matches",
        link: `${SEARCH_RESULT.fullUrl}/army-matches`,
        clearData: true,
      },
      {
        name: "Location Matches",
        link: `${SEARCH_RESULT.fullUrl}/location-matches-v2?cityId=-1`,
        clearData: true,
      },
      {
        name: "Salary Matches",
        link: `${SEARCH_RESULT.fullUrl}/salary-matches`,
        clearData: true,
      },
    ],
  },
  {
    name: "Activity Center",
    link: ACTIVITY_CENTER.fullUrl,
    items: [
      {
        name: "Interest Received",
        link: TAB_MAP.RECEIVED.route,
      },
      {
        name: "Interest Accepted",
        link: TAB_MAP.ACCEPTED.route,
      },
      {
        name: "Interest Sent",
        link: TAB_MAP.SENT.route,
      },
      {
        name: "Interest Declined",
        link: TAB_MAP.DECLINED.route,
      },
    ],
  },
  {
    name: "Search",
    link: SEARCH.fullUrl,
  },
  {
    name: "Messenger",
    link: MESSENGER.fullUrl,
  },
  {
    name: "Upgrade",
    link: MEMBERSHIP_PLAN.fullUrl,
  },
];

export const MOBILE_MASTER_NAV_MENU = [
  {
    name: "My Profile",
    link: ME_PFOILE.fullUrl,
  },
  {
    name: "My Photos",
    link: ADD_PHOTO.fullUrl,
  },
  {
    name: "Partner Preferences",
    link: PREFERRED_MATCHES.fullUrl,
  },
];

export const MASTER_NAV_WITH_OUT_AUTH_MENU = [
  {
    name: "Success Stories",
    target: "_blank",
    link: `${ENVIRONMENTS.ABOUT_DOMAIN}/success-stories.html`,
  },
  {
    name: "Upgrade",
    link: MEMBERSHIP_PLAN.fullUrl,
  },
  {
    name: "Contact Us",
    target: "_blank",
    link: `${ENVIRONMENTS.ABOUT_DOMAIN}/contact-us.html`,
  },
];

export const COMMON_ERROR = {
  SOMETHING_WENT_WRONG: `Something went wrong. Please try again`,
  PASSWORD_NOT_MATCH: `Password & Confirm Password do not match`,
  VALID_OTP: `Please enter valid otp`,
  SUCCESSFULLY_UPDATED: `Update successful! Your changes have been saved.`,
};

export const INTEREST_MESSAGE = {
  SENT: {
    message: "You have expressed interest to this profile.",
    bg: "#64b004",
  },
  RECEIVED: {
    message: "You have received interest request.",
    bg: "#288f30",
  },
  DECLINE: {
    message: "You have declined this member's express interest request.",
    bg: "#d9475c",
  },
  REQUEST_DECLINE: {
    message: "This profile has declined your interest.",
    bg: "#d9475c",
  },
  IGNORE: {
    message: "You have ignored this profile.",
    bg: "#795548",
  },
};

export const APP_LOADING_TEXT = [
  { text: "Finding your perfect 👫 match." },
  { text: "Nathuli 💫 is mandatory in marriage." },
  { text: "Celebrating Love,💞 Uttarakhand Style." },
  { text: "From Garhwal to Kumaon: Uniting Hearts 💞 the Uttarakhand Way." },
  { text: "Nathuli 💫: The Heart of Pahadi Weddings." },
  { text: "Journey of Love: From Pahadi Hearts 🚗 💑 to Eternity." },
  { text: "In the Lap of Nature,🌳 Love💞 Finds its Home" },
  { text: "Finding Love in the Heart of Uttarakhand 💚" },
];

export const RANDOM_COLORS = [
  "#fef4de",
  "#dcfce7",
  "#ffe2e6",
  "#f4e8fe",
  "#f4defe",
];
